import React, {useEffect} from "react";
import { Component } from "../../components/Component";
import { Component3 } from "../../components/Component3";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Component38 } from "../../icons/Component38";
import { Component48 } from "../../icons/Component48";
import "./style.css";

export const Case = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="case">
      <div className="div-2">
        <div className="the-challenge">
          <div className="frame">
            <div className="frame-2">
              <div className="text-wrapper-3">About:</div>
              <p className="p">
                Digited builds next-generation e-learning platforms for students, educators and institutes, converging
                them into an ecosystem for learning. Digited reached out to us to help them redesign their website with
                two objectives — to bring all their products in one and wow the end users.
              </p>
            </div>
            <div className="div-wrapper">
              <a className="text-wrapper-4" href="https://www.inconn.com/" rel="noopener noreferrer" target="_blank">
                Launch Project
              </a>
            </div>
          </div>
        </div>
        <div className="frame-3">
          <div className="overlap-group">
            <img className="vector" alt="Vector" src="/img/vector-4.svg" />
            <img className="img" alt="Vector" src="/img/vector-3.svg" />
            <img className="home-page" alt="Home page" src="/img/home-page-2.png" />
          </div>
          <div className="text-wrapper-5">Homepage</div>
        </div>
        <div className="element">
          <div className="overlap">
            <div className="text-wrapper-6">Visual Language</div>
            <div className="text-wrapper-6">Visual Language</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="overlap-2">
                <div className="text-wrapper-7">Aa</div>
                <div className="text-wrapper-8">Public Sans</div>
              </div>
              <div className="text-wrapper-9">AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz</div>
            </div>
          </div>
          <div className="frame-4">
            <div className="frame-5">
              <div className="frame-wrapper">
                <div className="frame-6">
                  <div className="text-wrapper-10">Purpureus</div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">#7C2FB2</div>
                  </div>
                </div>
              </div>
              <div className="frame-8">
                <div className="frame-9">
                  <div className="text-wrapper-10">Green Blue</div>
                  <div className="frame-7">
                    <div className="text-wrapper-11"> #1269B7</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-10">
              <div className="frame-11">
                <div className="frame-9">
                  <div className="text-wrapper-10">Vivid Sky Blue</div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">#50D4F2</div>
                  </div>
                </div>
              </div>
              <div className="frame-12">
                <div className="frame-9">
                  <div className="text-wrapper-10">Coral</div>
                  <div className="frame-7">
                    <div className="text-wrapper-11">#FF7F50</div>
                  </div>
                </div>
              </div>
              <div className="frame-13">
                <div className="frame-9">
                  <div className="text-wrapper-10">Turqoise</div>
                  <div className="frame-7">
                    <div className="text-wrapper-11"> #50D4F2</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-14">
              <div className="frame-15">
                <div className="frame-9">
                  <div className="text-wrapper-12">Light Yellow</div>
                  <div className="frame-7">
                    <div className="element-df"> #50D4F2</div>
                  </div>
                </div>
              </div>
              <div className="frame-16">
                <div className="frame-9">
                  <div className="text-wrapper-13">Clean Sheet</div>
                  <div className="frame-7">
                    <div className="text-wrapper-14">#FFFFFF</div>
                  </div>
                </div>
              </div>
              <div className="frame-17">
                <div className="frame-9">
                  <div className="text-wrapper-15">Ink Black</div>
                  <div className="frame-7">
                    <div className="element-ddd"> #0D0D0D</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-18">
            <img className="img-2" alt="Section finance" src="/img/section-06-finance-and-banking-1.png" />
            <img className="img-2" alt="Section medicine" src="/img/section-06-medicine-1.png" />
            <img className="img-2" alt="Section law" src="/img/section-06-law-1.png" />
            <img className="img-2" alt="Section science" src="/img/section-06-science-1.png" />
            <img className="img-3" alt="Section business" src="/img/section-06-business-management-1.png" />
            <img className="img-3" alt="Section railways" src="/img/section-06-railways-1.png" />
            <img className="img-3" alt="Section" src="/img/section-06-engineering-1.png" />
            <img className="img-3" alt="Section" src="/img/section-06-journalism-and-media-1.png" />
          </div>
        </div>
        <div className="frame-19">
          <div className="text-wrapper-16">The Solution</div>
          <p className="we-spruced-up-the">
            We spruced up the brand assets to connect all their products in one with a modernised approach&nbsp;&nbsp;—
            by introducing a fresh secondary scheme and a typeface that aligns perfectly with the logo to look forward
            to new futures and redesign the pages following this style.
          </p>
        </div>
        <div className="digited-business">
          <div className="overlap-3">
            <img className="section-main" alt="Section main" src="/img/section-01-main-3.png" />
            <div className="frame-20">
              <p className="text-wrapper-17">Readily available community of learners</p>
              <p className="text-wrapper-18">Launch courses free of cost</p>
              <p className="text-wrapper-19">
                Share your valuable knowledge with the world and make a positive impact. Use metrics to test out
                different teaching methodologies and track the ones that bring in impactful results.
              </p>
              <p className="we-succeed-only-when">
                We succeed only when you do (10% cut). Launch your courses without an upfront cost. Experiment with
                different pricing models &amp; strategies and learn what works best for your course and your audience.
              </p>
              <p className="text-wrapper-20">Get charged only after you generate revenue</p>
              <div className="frame-21">
                <div className="text-wrapper-21">LEARN MORE</div>
                <img className="arrow-forward" alt="Arrow forward" src="/img/arrow-forward-outline-1-10.svg" />
              </div>
              <p className="text-wrapper-22">
                Find your audience from our community of over 20,000 learners and gain exposure. Establish your
                credibility and build your tribe.
              </p>
              <img className="section-image" alt="Section image" src="/img/section-02-image-01-3.png" />
              <img className="section-image-2" alt="Section image" src="/img/section-02-image-02-3.png" />
              <img className="section-image-3" alt="Section image" src="/img/section-02-image-03-3.png" />
              <div className="frame-22">
                <div className="type-wrapper">
                  <div className="type">Benefits</div>
                </div>
                <div className="frame-23">
                  <p className="type-2">
                    <span className="span">Start teaching &amp; make an impact right away </span>
                    <span className="text-wrapper-23">Turn your expertise into profit by </span>
                    <span className="text-wrapper-24">10x</span>
                    <span className="text-wrapper-23">.</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="frame-24">
              <div className="frame-23">
                <p className="start-teaching-share">Start teaching &amp; share your expertise with the world</p>
                <p className="text-wrapper-25">
                  Build your own courses, publish quizzes and tests and manage your audience.
                </p>
              </div>
              <button className="buttons">
                <div className="text-wrapper-26">Join now</div>
              </button>
            </div>
            <div className="frame-25">
              <div className="frame-26">
                <div className="frame-27">
                  <div className="image-wrapper">
                    <img className="image" alt="Image" src="/img/image-3-12.png" />
                  </div>
                  <div className="frame-28">
                    <Component
                      className="component-5"
                      divClassName="component-instance"
                      expandMoreBlack="/img/expand-more-black-24dp-1-1-4.svg"
                      expandMoreBlackClassName="component-5-instance"
                      property1="frame-137"
                      text="Products"
                    />
                    <Component
                      className="component-5"
                      divClassName="component-instance"
                      hasExpandMoreBlack={false}
                      property1="frame-137"
                      text="About Us"
                    />
                  </div>
                </div>
                <div className="frame-29">
                  <div className="text-wrapper-26">Contact Us</div>
                </div>
              </div>
            </div>
          </div>
          <div className="CT-as">
            <p className="text-wrapper-27">Ready for your next step?</p>
            <p className="text-wrapper-28">Let us help you get there</p>
            <div className="buttons-2">
              <div className="text-wrapper-29">Get in touch</div>
            </div>
          </div>
          <div className="frame-30">
            <div className="mask-group-wrapper">
              <img className="mask-group" alt="Mask group" src="/img/mask-group-9.png" />
            </div>
            <div className="company-links">
              <div className="text-wrapper-30">DigitEd</div>
              <div className="text-wrapper-31">About Us</div>
              <div className="text-wrapper-32">Contact Us</div>
            </div>
            <div className="resources-links">
              <div className="text-wrapper-30">Products</div>
              <div className="text-wrapper-32">DigitEd Business</div>
              <div className="text-wrapper-33">DigitEd Campus</div>
              <div className="text-wrapper-31">DigitEd</div>
            </div>
            <div className="frame-31">
              <div className="text-wrapper-34">Get in touch</div>
              <div className="frame-32">
                <div className="frame-33">
                  <img
                    className="icon-instance-node"
                    alt="Mail outline black"
                    src="/img/mail-outline-black-24dp-1-8.svg"
                  />
                  <div className="text-wrapper-35">support@digitedlabs.com</div>
                </div>
                <div className="frame-34">
                  <img className="icon-instance-node" alt="Place black" src="/img/place-black-24dp-1-8.svg" />
                  <div className="t-hub-gachibowli">
                    T-Hub, Gachibowli, <br />
                    Hyderabad, 500032
                  </div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-36">
              An ed-tech startup founded by people passionate about transforming lives through education, employment and
              employability
            </p>
            <div className="frame-35">
              <img className="vector-2" alt="Vector" src="/img/vector-1-9.svg" />
              <div className="frame-36">
                <p className="text-wrapper-37">© 2021. All rights reserved</p>
                <div className="frame-37">
                  <div className="text-wrapper-37">Follow us on:</div>
                  <Component48 className="icon-instance-node" />
                  <Component38 className="icon-instance-node" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-38">
            <div className="frame-39">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-unlimited-storage-1.svg" />
              <div className="frame-40">
                <div className="type-3">Unlimited Storage &amp; Bandwidth</div>
                <p className="text-wrapper-38">
                  Create &amp; Stream courses in high definition(HD) with rich multi-media content at no additional
                  cost.
                </p>
              </div>
            </div>
            <div className="frame-41">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-course-marketing-1.svg" />
              <div className="frame-40">
                <p className="type-3">Course Marketing &amp; Institute Branding</p>
                <p className="text-wrapper-38">
                  We brand your Institute &amp; help your courses to be listed on top of the searches on Google with
                  zero effort
                </p>
              </div>
            </div>
            <div className="frame-42">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-powerful-integrations-1.svg" />
              <div className="frame-40">
                <div className="type-3">Powerful Integrations &amp; Capabilities</div>
                <p className="text-wrapper-38">
                  Schedule Live Classes, Send Smart Notifications, Seamlessly review &amp; update Course content.
                </p>
              </div>
            </div>
            <div className="frame-43">
              <img
                className="section-icon"
                alt="Section icon"
                src="/img/section-03-icon-customer-relationship-manager-1.svg"
              />
              <div className="frame-40">
                <div className="type-3">Customer Relationship Manager</div>
                <p className="text-wrapper-38">
                  A dedicated professional to smoothen the onboarding experience and help you launch a new course.
                </p>
              </div>
            </div>
            <div className="frame-44">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-modern-cloud-tech-1.svg" />
              <div className="frame-45">
                <div className="type-4">Modern Cloud Technologies</div>
                <p className="text-wrapper-39">
                  Designed for scale with zero downtime, your popular course can handle any number of students at any
                  given time.
                </p>
              </div>
            </div>
            <div className="frame-46">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-premium-platform-1.svg" />
              <div className="frame-45">
                <p className="type-4">Premium Platform with Lifetime Upgrades</p>
                <p className="text-wrapper-39">
                  To make your courses stand out as a premium offering with up-to-date content &amp; technology
                </p>
              </div>
            </div>
            <div className="frame-47">
              <div className="type-wrapper">
                <div className="type">Features</div>
              </div>
              <div className="frame-23">
                <p className="type-5">Manage your courses more effectively with our state-of-the-art infrastructure</p>
              </div>
            </div>
          </div>
          <div className="overlap-4">
            <div className="frame-48">
              <div className="overlap-5">
                <div className="frame-49">
                  <div className="section-icon" />
                </div>
                <div className="frame-50">
                  <div className="section-icon" />
                </div>
                <img className="section-main-2" alt="Section main" src="/img/section-04-main-3.png" />
              </div>
              <p className="text-wrapper-40">All features for one price.</p>
              <p className="text-wrapper-41">Get access to a business account at an affordable price.</p>
            </div>
            <div className="text-container">
              <p className="text-wrapper-42">
                DigitEd made it simple for me to manage my courses and interact with students. I’m super impressed!
              </p>
              <img className="frame-51" alt="Frame" src="/img/frame-226-4.svg" />
              <div className="frame-52">
                <div className="ellipse-wrapper">
                  <img className="ellipse" alt="Ellipse" src="/img/ellipse-21-4.png" />
                </div>
                <div className="frame-53">
                  <div className="text-wrapper-43">Rosaline McArthur</div>
                  <div className="text-wrapper-44">Senior Product Manager</div>
                </div>
              </div>
              <div className="frame-54">
                <div className="ellipse-2" />
                <div className="ellipse-3" />
                <div className="ellipse-2" />
              </div>
            </div>
          </div>
        </div>
        <img className="digit-ed" alt="Digit ed" src="/img/digited.png" />
        <div className="digited-campus">
          <div className="overlap-3">
            <img className="section-main-3" alt="Section main" src="/img/section-01-main-2.png" />
            <div className="frame-55">
              <div className="frame-23">
                <p className="text-wrapper-45">Deliver better learning and build brighter futures</p>
                <p className="enable-your">
                  Enable your institute and employees with DigitEd campus and&nbsp;&nbsp;take your students and
                  employees future to new heights.
                </p>
              </div>
              <div className="buttons-3">
                <div className="text-wrapper-26">Book a demo</div>
              </div>
            </div>
            <div className="frame-20">
              <p className="text-wrapper-17">Reach every student no matter where they are</p>
              <p className="text-wrapper-46">Collaborate seamlessly with your department</p>
              <p className="text-wrapper-47">
                Stay connected with your colleagues, share ideas and resources, and achieve your goals together!
                Teamwork makes the dream work.
              </p>
              <p className="text-wrapper-48">
                LMS ecosystem - Enhanced communication and collaboration between teachers, administrators, and students.
                Get data-driven insights and make better decisions.
              </p>
              <p className="text-wrapper-20">Embrace the future of education with confidence</p>
              <div className="frame-56">
                <div className="text-wrapper-21">BOOK A DEMO</div>
                <img className="arrow-forward" alt="Arrow forward" src="/img/arrow-forward-outline-1-9.svg" />
              </div>
              <p className="effortlessly-deliver">
                Effortlessly deliver course materials &amp; increase accessibility of educational resources. Communicate
                with your students at lightning speed and ensure no one is left behind.
              </p>
              <div className="frame-57">
                <div className="type-wrapper">
                  <div className="type">Benefits</div>
                </div>
                <div className="frame-23">
                  <p className="type-6">Use DigitEd to simplify your life as an educator</p>
                </div>
              </div>
              <img className="section-image" alt="Section image" src="/img/section-02-image-01-2.png" />
              <img className="section-image-2" alt="Section image" src="/img/section-02-image-02-2.png" />
              <img className="section-image-3" alt="Section image" src="/img/section-02-image-03-2.png" />
              <div className="frame-58">
                <div className="text-wrapper-21">BOOK A DEMO</div>
                <img className="arrow-forward" alt="Arrow forward" src="/img/arrow-forward-outline-1-8.svg" />
              </div>
              <div className="frame-59">
                <div className="text-wrapper-21">BOOK A DEMO</div>
                <img className="arrow-forward" alt="Arrow forward" src="/img/arrow-forward-outline-1-7.svg" />
              </div>
            </div>
            <div className="frame-25">
              <div className="frame-26">
                <div className="frame-27">
                  <div className="image-wrapper">
                    <img className="image" alt="Image" src="/img/image-3-10.png" />
                  </div>
                  <div className="frame-28">
                    <Component
                      className="component-5"
                      divClassName="component-instance"
                      expandMoreBlack="/img/expand-more-black-24dp-1-1-3.svg"
                      expandMoreBlackClassName="component-5-instance"
                      property1="frame-137"
                      text="Products"
                    />
                    <Component
                      className="component-5"
                      divClassName="component-instance"
                      hasExpandMoreBlack={false}
                      property1="frame-137"
                      text="About Us"
                    />
                  </div>
                </div>
                <div className="frame-29">
                  <div className="text-wrapper-26">Contact Us</div>
                </div>
              </div>
            </div>
          </div>
          <div className="CT-as-2">
            <p className="text-wrapper-49">See what DigitEd can do for you</p>
            <p className="find-out-how-it-can">
              Find out how it can elevate your teaching experience &amp; unlock the full potential of your classroom.
            </p>
            <div className="buttons-4">
              <div className="text-wrapper-29">Book a demo today</div>
            </div>
          </div>
          <div className="frame-60">
            <div className="img-wrapper">
              <img className="mask-group" alt="Mask group" src="/img/mask-group-8.png" />
            </div>
            <div className="company-links-2">
              <div className="text-wrapper-30">DigitEd</div>
              <div className="text-wrapper-31">About Us</div>
              <div className="text-wrapper-32">Contact Us</div>
            </div>
            <div className="resources-links">
              <div className="text-wrapper-30">Products</div>
              <div className="text-wrapper-32">DigitEd Business</div>
              <div className="text-wrapper-33">DigitEd Campus</div>
              <div className="text-wrapper-31">DigitEd</div>
            </div>
            <div className="frame-31">
              <div className="text-wrapper-34">Get in touch</div>
              <div className="frame-32">
                <div className="frame-33">
                  <img className="mail-outline-black" alt="Mail outline black" src="/img/mask-group-8.png" />
                  <div className="text-wrapper-35">support@digitedlabs.com</div>
                </div>
                <div className="frame-34">
                  <img className="place-black" alt="Place black" src="/img/mask-group-8.png" />
                  <div className="t-hub-gachibowli">
                    T-Hub, Gachibowli, <br />
                    Hyderabad, 500032
                  </div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-36">
              An ed-tech startup founded by people passionate about transforming lives through education, employment and
              employability
            </p>
            <div className="frame-35">
              <img className="vector-3" alt="Vector" src="/img/vector-1-8.png" />
              <div className="frame-36">
                <p className="text-wrapper-37">© 2021. All rights reserved</p>
                <div className="frame-37">
                  <div className="text-wrapper-37">Follow us on:</div>
                  <Component3 property1="logo-instagram-1" state="default" />
                  <Component3 property1="logo-linkedin-1" state="default" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-61">
            <div className="frame-62">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-customize-lms-1.svg" />
              <div className="frame-45">
                <p className="type-4">Customize LMS to fit your requirements</p>
                <p className="text-wrapper-39">
                  Get a tailored learning experience that meets the needs of your students and teachers, without having
                  to compromise on functionality or usability.
                </p>
              </div>
            </div>
            <div className="frame-63">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-simple-grading-1.svg" />
              <div className="frame-40">
                <p className="type-3">Simple grading &amp; assessment process</p>
                <p className="text-wrapper-38">
                  Automated grading and assessment process, saving time for teachers and providing immediate feedback to
                  students.
                </p>
              </div>
            </div>
            <div className="frame-64">
              <img
                className="section-icon"
                alt="Section icon"
                src="/img/section-03-icon-reduce-cost-and-environmental-impact-1.svg"
              />
              <div className="frame-40">
                <p className="type-3">Reduce cost &amp; environmental impact</p>
                <p className="text-wrapper-38">
                  DigitEd reduces costs associated with traditional paper-based teaching methods and has a lower impact.
                </p>
              </div>
            </div>
            <div className="frame-65">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-staff-training-1.svg" />
              <div className="frame-40">
                <div className="type-3">Staff training</div>
                <p className="text-wrapper-38">
                  Effortlessly train and onboard staff members to DigitEd platform, saving time and providing a seamless
                  transition to the new ecosystem.
                </p>
              </div>
            </div>
            <div className="frame-66">
              <img className="section-icon" alt="Section icon" src="/img/section-03-icon-improved-organization-1.svg" />
              <div className="frame-45">
                <div className="type-4">Improved organization and management</div>
                <p className="text-wrapper-39">
                  DigitEd streamlines teacher and administrator workflows by organizing course materials, assignments,
                  and records in one location.
                </p>
              </div>
            </div>
            <div className="frame-67">
              <img
                className="section-icon"
                alt="Section icon"
                src="/img/section-03-icon-monitor-student-progress-1.svg"
              />
              <div className="frame-45">
                <p className="type-4">Monitor student progress &amp; engagement</p>
                <p className="text-wrapper-39">
                  Real-time tracking of student progress and performance, allowing for personalized learning experiences
                  and targeted interventions.
                </p>
              </div>
            </div>
            <div className="frame-68">
              <div className="type-wrapper">
                <div className="type">Features</div>
              </div>
              <div className="frame-23">
                <div className="type-7">Streamline your teaching experience</div>
                <p className="text-wrapper-50">
                  Our intuitive LMS infrastructure takes the hassle out of adapting to new technology and software,
                  allowing you to focus on what you do best.
                </p>
              </div>
            </div>
          </div>
          <div className="text-container-2">
            <p className="text-wrapper-42">
              The analytics &amp; reporting features have been a game-changer for me. It&#39;s helped me improve my
              teaching and keep my students on track.
            </p>
            <img className="frame-51" alt="Frame" src="/img/frame-226-3.svg" />
            <div className="frame-52">
              <div className="ellipse-wrapper">
                <img className="ellipse" alt="Ellipse" src="/img/ellipse-21-3.png" />
              </div>
              <div className="frame-53">
                <div className="text-wrapper-43">Rosaline McArthur</div>
                <div className="text-wrapper-44">Senior Product Manager</div>
              </div>
            </div>
            <div className="frame-54">
              <div className="ellipse-2" />
              <div className="ellipse-3" />
              <div className="ellipse-2" />
            </div>
          </div>
        </div>
        <div className="about-us">
          <div className="overlap-6">
            <img className="section-BG-image" alt="Section BG image" src="/img/section-01-bg-image-1.png" />
            <div className="buttons-5">
              <div className="text-wrapper-26">Let’s Connect</div>
            </div>
            <p className="text-wrapper-51">
              We are passionate about transforming lives through education, employment and employability
            </p>
            <div className="frame-69">
              <div className="type-8">Who are we?</div>
            </div>
            <div className="frame-70">
              <div className="frame-71">
                <div className="frame-27">
                  <div className="frame-72">
                    <img className="image" alt="Image" src="/img/image-3-8.png" />
                  </div>
                  <div className="frame-73">
                    <Component
                      className="component-5"
                      divClassName="component-instance"
                      expandMoreBlack="/img/expand-more-black-24dp-1-1-2.svg"
                      expandMoreBlackClassName="design-component-instance-node"
                      property1="frame-137"
                      text="Products"
                    />
                    <Component
                      className="component-5"
                      divClassName="component-instance"
                      hasExpandMoreBlack={false}
                      property1="frame-137"
                      text="About Us"
                    />
                  </div>
                </div>
                <div className="frame-74">
                  <div className="text-wrapper-26">Contact Us</div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-7">
            <div className="frame-75">
              <div className="text-wrapper-52">Our Mission</div>
              <div className="frame-76">
                <p className="text-wrapper-53">
                  To build a next-generation e-learning platform for competitive exam preparation and converge it into
                  an ecosystem for learning.
                </p>
                <p className="text-wrapper-54">
                  We believe such convergence is necessary for content to innovate faster and make its access more
                  affordable. It does so by converging data touch points to create an intelligent platform that not just
                  helps individuals to learn better, but improvises itself imbibing learner with meta cognitive skills
                  necessary to self-direct.
                  <br />
                  DigitEd as it is envisioned today will be a base with powerful integrations to make a universally
                  accessible learning platform that delivers learning outcomes to its learners, giving them the choice,
                  making education truly accessible, affordable, equitable and is lifelong.
                </p>
              </div>
            </div>
          </div>
          <div className="overlap-8">
            <p className="text-wrapper-55">
              Check out our current job openings! We offer a great work environment, competitive compensation and
              benefits, and plenty of opportunities to learn and grow.
            </p>
            <div className="buttons-6">
              <div className="text-wrapper-26">View Careers</div>
            </div>
            <div className="text-wrapper-56">Join Our Team</div>
          </div>
          <div className="overlap-9">
            <img className="section-BG-image-2" alt="Section BG image" src="/img/section-04-bg-image.png" />
            <div className="frame-77">
              <div className="frame-78">
                <img className="img-4" alt="Section icon" src="/img/section-04-icon-design-and-development-1.svg" />
                <div className="frame-79">
                  <div className="text-wrapper-57">Design &amp; Development</div>
                  <p className="text-wrapper-58">
                    We love to develop products like Geneo, Good Worker and DigitEd that sustainably transform the lives
                    of millions.
                  </p>
                </div>
              </div>
              <div className="frame-78">
                <img className="img-4" alt="Section icon" src="/img/section-04-icon-marketing-and-scale-1.svg" />
                <div className="frame-79">
                  <div className="text-wrapper-57">Marketing &amp; Scale</div>
                  <p className="text-wrapper-58">
                    Product is as good as the meaning the end-user derives from it. We not just build products but
                    market it at scale
                  </p>
                </div>
              </div>
              <div className="frame-78">
                <img className="img-4" alt="Section icon data" src="/img/section-04-icon-data-and-cloud-tech-1.svg" />
                <div className="frame-79">
                  <div className="text-wrapper-57">Data &amp; Cloud Technologies</div>
                  <p className="text-wrapper-58">
                    Design ethos of scalable solutions led us to develop in-house expertise in the technologies of the
                    future
                  </p>
                </div>
              </div>
              <div className="frame-78">
                <img className="img-4" alt="Section icon" src="/img/section-04-icon-subject-matter-expert-1.svg" />
                <div className="frame-79">
                  <div className="text-wrapper-57">Subject Matter Expertise</div>
                  <p className="text-wrapper-58">
                    To keep Product in sync with end-user, subject expertise is embedded in design DNA of every product
                    feature
                  </p>
                </div>
              </div>
            </div>
            <div className="text-wrapper-59">Our Expertise</div>
          </div>
          <div className="frame-80">
            <div className="overlap-group-3">
              <img className="mask-group" alt="Mask group" src="/img/mask-group-7.png" />
            </div>
            <div className="company-links-2">
              <div className="text-wrapper-30">DigitEd</div>
              <div className="text-wrapper-31">About Us</div>
              <div className="text-wrapper-32">Contact Us</div>
            </div>
            <div className="resources-links">
              <div className="text-wrapper-30">Products</div>
              <div className="text-wrapper-32">DigitEd Business</div>
              <div className="text-wrapper-33">DigitEd Campus</div>
              <div className="text-wrapper-31">DigitEd</div>
            </div>
            <div className="frame-31">
              <div className="text-wrapper-34">Get in touch</div>
              <div className="frame-32">
                <div className="frame-33">
                  <img className="mail-outline-black-2" alt="Mail outline black" src="/img/mask-group-7.png" />
                  <div className="text-wrapper-35">support@digitedlabs.com</div>
                </div>
                <div className="frame-34">
                  <img className="place-black-dp" alt="Place black" src="/img/mask-group-7.png" />
                  <div className="t-hub-gachibowli-2">
                    T-Hub, Gachibowli, <br />
                    Hyderabad, 500032
                  </div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-36">
              An ed-tech startup founded by people passionate about transforming lives through education, employment and
              employability
            </p>
            <div className="frame-81">
              <img className="vector-4" alt="Vector" src="/img/vector-1-7.png" />
              <div className="frame-36">
                <p className="text-wrapper-37">© 2021. All rights reserved</p>
                <div className="frame-37">
                  <div className="text-wrapper-37">Follow us on:</div>
                  <Component3 property1="logo-instagram-1" state="default" />
                  <Component3 property1="logo-linkedin-1" state="default" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-82">
            <div className="text-wrapper-52">Our Vision</div>
            <div className="frame-76">
              <p className="text-wrapper-53">
                To build a learning ecosystem for the 21st century world. A future where technology will seamlessly
                integrate with the learning process.
              </p>
              <p className="text-wrapper-54">
                It will be a symbiotic environment where people interact with the content, technologies, and data that
                surround them. The ecosystem would transform the present fragmented and unequitable learning spaces into
                a more diverse, rich and interconnected whole. The transformation that we believe is key to humanity’s
                next stage of evolution.
                <br />
                We at DigitEd are committed to invest and build such a future of learning. A future where there are no
                hindrances for an individual wanting to learn. A future where learning redefines the boundaries of human
                understanding. A future that gets shaped as we learn.
              </p>
            </div>
          </div>
          <p className="text-wrapper-60">
            At DigitEd Labs, we pride ourselves on having a talented and diverse team of professionals who are dedicated
            to delivering the best possible experience for our customers.
          </p>
          <div className="text-wrapper-61">Meet our Team</div>
          <div className="CT-as-3">
            <p className="text-wrapper-62">Ready for your next step?</p>
            <p className="text-wrapper-63">Let us help you get there</p>
            <div className="buttons-7">
              <div className="text-wrapper-29">Get in touch today</div>
            </div>
          </div>
          <div className="meet-the-chefs">
            <div className="list-wrapper">
              <div className="list">
                <img className="section-image-4" alt="Section image" src="/img/section-05-image-01-1.png" />
                <img className="section-image-5" alt="Section image" src="/img/section-05-image-02-1.png" />
                <img className="section-image-6" alt="Section image" src="/img/section-05-image-03-1.png" />
                <img className="section-image-6" alt="Section image" src="/img/section-05-image-04-1.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us">
          <div className="overlap-10">
            <div className="frame-83">
              <div className="frame-84">
                <div className="frame-85">
                  <div className="frame-86">
                    <div className="frame-87">
                      <p className="div-3">
                        <span className="text-wrapper-64">Full Name </span>
                        <span className="text-wrapper-65">*</span>
                      </p>
                    </div>
                    <div className="frame-87">
                      <p className="div-3">
                        <span className="text-wrapper-64">Email </span>
                        <span className="text-wrapper-65">*</span>
                      </p>
                    </div>
                    <div className="frame-87">
                      <div className="text-wrapper-66">Phone number</div>
                    </div>
                    <div className="frame-88">
                      <div className="text-wrapper-66">Enter your message</div>
                    </div>
                  </div>
                  <div className="buttons-8">
                    <div className="text-wrapper-67">Talk to us now!</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-89">
              <p className="text-wrapper-68">Ready to take your learner’s journey to the next level?</p>
              <p className="text-wrapper-69">
                Get in touch with our team. Learn more about Digited solutions. We will help you find the right products
                and pricing for your business.
              </p>
            </div>
            <div className="frame-90">
              <div className="frame-91">
                <div className="frame-92">
                  <div className="frame-93">
                    <img className="image-2" alt="Image" src="/img/image-3-6.png" />
                  </div>
                  <div className="frame-94">
                    <Component
                      className="component-2"
                      divClassName="component-3"
                      expandMoreBlack="/img/expand-more-black-24dp-1-1-1.svg"
                      expandMoreBlackClassName="component-4"
                      property1="frame-137"
                      text="Products"
                    />
                    <Component
                      className="component-2"
                      divClassName="component-3"
                      hasExpandMoreBlack={false}
                      property1="frame-137"
                      text="About Us"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-95">
            <div className="overlap-group-4">
              <img className="mask-group-2" alt="Mask group" src="/img/section-01-image-01-1.png" />
            </div>
            <div className="company-links-3">
              <div className="text-wrapper-70">DigitEd</div>
              <div className="text-wrapper-71">About Us</div>
              <div className="text-wrapper-72">Contact Us</div>
            </div>
            <div className="resources-links-2">
              <div className="text-wrapper-70">Products</div>
              <div className="text-wrapper-72">DigitEd Business</div>
              <div className="text-wrapper-73">DigitEd Campus</div>
              <div className="text-wrapper-71">DigitEd</div>
            </div>
            <div className="frame-96">
              <div className="text-wrapper-74">Get in touch</div>
              <div className="frame-97">
                <div className="frame-98">
                  <img className="mail-outline-black-3" alt="Mail outline black" src="/img/section-01-image-01-1.png" />
                  <div className="text-wrapper-75">support@digitedlabs.com</div>
                </div>
                <div className="frame-99">
                  <img className="place-black-2" alt="Place black" src="/img/section-01-image-01-1.png" />
                  <div className="t-hub-gachibowli-3">
                    T-Hub, Gachibowli, <br />
                    Hyderabad, 500032
                  </div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-76">
              An ed-tech startup founded by people passionate about transforming lives through education, employment and
              employability
            </p>
            <div className="frame-100">
              <img className="vector-5" alt="Vector" src="/img/section-01-image-03-3.png" />
              <div className="frame-36">
                <p className="text-wrapper-77">© 2021. All rights reserved</p>
                <div className="frame-101">
                  <div className="text-wrapper-77">Follow us on:</div>
                  <Component3 property1="logo-instagram-1" state="default" />
                  <Component3 property1="logo-linkedin-1" state="default" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-102">
          <div className="mobile-home-page">
            <div className="overlap-11">
              <div className="rectangle" />
              <div className="metrics">
                <div className="frame-103">
                  <div className="frame-104">
                    <div className="frame-105">
                      <div className="text-wrapper-78">50+</div>
                      <div className="text-wrapper-79">Instructors</div>
                    </div>
                    <div className="frame-106">
                      <div className="text-wrapper-78">10,000+</div>
                      <div className="text-wrapper-79">Active Learners</div>
                    </div>
                  </div>
                  <div className="frame-104">
                    <div className="frame-107">
                      <div className="text-wrapper-78">100+</div>
                      <div className="text-wrapper-79">Courses</div>
                    </div>
                    <div className="frame-107">
                      <div className="text-wrapper-78">100+</div>
                      <div className="text-wrapper-79">Managers</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-108">
                <div className="frame-109">
                  <div className="frame-110">
                    <div className="frame-111">
                      <div className="frame-112">
                        <div className="text-wrapper-80">DigitEd</div>
                      </div>
                    </div>
                    <p className="text-wrapper-81">Built to learn and share knowledge with ease</p>
                  </div>
                </div>
                <div className="frame-113">
                  <div className="frame-114">
                    <p className="text-wrapper-82">
                      Discover learning like never before. Access personalised content, learn better with the largest
                      community of 20,000+ mentors and learners that speak your language and push your potential to new
                      levels everyday.
                    </p>
                    <button className="button">
                      <div className="text-wrapper-83">Learn More</div>
                    </button>
                  </div>
                </div>
              </div>
              <img className="section-main-4" alt="Section main" src="/img/section-02-main.png" />
              <img className="section-BG-main" alt="Section BG main" src="/img/section-03-bg-main.png" />
              <div className="frame-115">
                <p className="type-9">Why DigitEd works so well?</p>
                <div className="frame-116">
                  <div className="frame-117">
                    <img className="section-icon-2" alt="Section icon" src="/img/section-01-image-01-1.png" />
                    <div className="frame-118">
                      <p className="text-wrapper-84">A learning journey with the experts</p>
                      <p className="text-wrapper-85">
                        Find verified, highly rated, &amp; most up-to-date courses from popular Institutes and verified
                        experts excited to share their professional journeys with you.
                      </p>
                    </div>
                  </div>
                  <div className="frame-119">
                    <img className="section-icon-3" alt="Section icon" src="/img/section-01-image-01-1.png" />
                    <div className="frame-120">
                      <div className="text-wrapper-84">Learn better together</div>
                      <p className="text-wrapper-86">
                        Connect with a community of curious learners that speak your language and support you throughout
                        in your learning journey.
                      </p>
                    </div>
                  </div>
                  <div className="frame-119">
                    <img className="section-icon-4" alt="Section icon" src="/img/section-01-image-01-1.png" />
                    <div className="frame-120">
                      <div className="text-wrapper-84">Unlock your learning potential</div>
                      <p className="text-wrapper-87">
                        Level up faster by share your knowledge.&nbsp;&nbsp;collaborate and compete - with like-minded
                        learners and mentors - All in a single app.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-4">
              <img className="navbar-company-logo" alt="Navbar company logo" src="/img/navbar-company-logo.png" />
              <img className="frame-121" alt="Frame" src="/img/frame-163051-5.svg" />
              <div className="frame-122">
                <div className="text-wrapper-88">Contact Us</div>
              </div>
            </div>
            <div className="group-wrapper">
              <div className="group-2">
                <div className="section-image-wrapper">
                  <img className="section-image-7" alt="Section image" src="/img/section-01-image-02.png" />
                </div>
                <img className="section-image-8" alt="Section image" src="/img/section-01-image-03.png" />
              </div>
            </div>
            <div className="hero-text">
              <div className="frame-123">
                <div className="text-wrapper-89">Empowering</div>
                <PropertyWrapper
                  className="component-6"
                  divClassName="component-7"
                  divClassName1="component-9"
                  divClassNameOverride="component-8"
                  groupClassName="component-6-instance"
                  property1="one"
                />
              </div>
              <p className="text-wrapper-90">with sustainable learning ecosystems for good.</p>
            </div>
            <div className="frame-124">
              <div className="buttons-9">
                <div className="text-wrapper-91">Let’s Connect</div>
              </div>
              <button className="buttons-10">
                <div className="text-wrapper-92">Learn More</div>
              </button>
            </div>
            <p className="headline">
              {" "}
              DigitEd labs builds technology that help people launch and share their knowledge to build thriving
              communities and enhanced learning outcomes.
            </p>
            <div className="overlap-12">
              <img className="headline-2" alt="Headline" src="/img/section-01-image-01-1.png" />
              <img className="section-main-5" alt="Section main" src="/img/section-01-image-03-3.png" />
            </div>
            <div className="frame-125">
              <div className="frame-126">
                <div className="type-10">Our Products</div>
              </div>
              <div className="frame-127">
                <div className="text-wrapper-93">
                  One Platform. <br />
                  Endless Possibilities.
                </div>
                <p className="create-immersive">
                  Create immersive learning experiences and scale your programs with enterprise grade features, consumer
                  grade-UX, and the support you need to build a better future tomorrow. Connect with us
                  to&nbsp;&nbsp;see how we can help you grow today!
                </p>
              </div>
            </div>
            <div className="frame-128">
              <div className="frame-129">
                <img className="section-image-9" alt="Section image" src="/img/section-01-image-03-3.png" />
                <div className="overlap-group-5">
                  <div className="frame-130">
                    <div className="frame-131">
                      <div className="type-11">Digited Business</div>
                    </div>
                    <div className="frame-132">
                      <div className="frame-133">
                        <p className="type-12">Grow your business 10x faster</p>
                        <p className="type-13">
                          Streamline your course creation process with integrated modern LMS features and publish your
                          course to a community of learners readily available from day 1.
                        </p>
                      </div>
                      <div className="frame-134">
                        <div className="text-wrapper-94">Learn More</div>
                        <img className="arrow-forward-2" alt="Arrow forward" src="/img/section-01-image-01-1.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-135">
                <img className="section-image-10" alt="Section image" src="/img/section-01-image-03-3.png" />
                <div className="frame-136">
                  <div className="frame-131">
                    <div className="type-11">Digited Campus</div>
                  </div>
                  <div className="frame-137">
                    <div className="frame-133">
                      <p className="type-12">Take your institute to new heights</p>
                      <p className="text-wrapper-95">
                        Enable your institute and employees with digited’s advanced enterprise grade features, consumer
                        grade-UX and take your learning outcomes to new heights
                      </p>
                    </div>
                    <div className="frame-134">
                      <div className="text-wrapper-94">Learn More</div>
                      <img className="arrow-forward-3" alt="Arrow forward" src="/img/section-01-image-01-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="section-image-11" alt="Section image" src="/img/section-01-image-03-3.png" />
            <div className="frame-138">
              <div className="frame-139">
                <div className="type-11">Our Vision</div>
              </div>
              <div className="frame-113">
                <div className="frame-142">
                  <div className="text-wrapper-96">Taking humans forward</div>
                  <p className="text-wrapper-97">
                    DigitEd is on a mission to connect the technological possibilities for people to collaborate and
                    transform learning for good. We believe the more people we can empower through world-class learning,
                    the more game-changing innovations and discoveries we’ll see.
                  </p>
                </div>
                <button className="buttons-11">
                  <div className="text-wrapper-98">Learn More</div>
                </button>
              </div>
            </div>
            <img className="section-main-6" alt="Section main" src="/img/section-01-image-03-3.png" />
            <div className="text-container-3">
              <p className="text-wrapper-99">
                On Digited, you learn from the experts. As you are taking courses, you are really learning from the best
                instructors in the world.
              </p>
              <img className="section-quotes" alt="Section quotes" src="/img/section-01-image-01-1.png" />
              <div className="frame-143">
                <img className="section-image-12" alt="Section image" src="/img/section-01-image-03-3.png" />
                <div className="frame-144">
                  <div className="text-wrapper-100">Rosaline McArthur</div>
                  <div className="text-wrapper-101">Senior Product Manager</div>
                </div>
              </div>
              <div className="frame-145">
                <div className="ellipse-4" />
                <div className="ellipse-5" />
                <div className="ellipse-4" />
              </div>
            </div>
            <img className="section-main-7" alt="Section main" src="/img/section-01-image-03-3.png" />
            <div className="frame-146">
              <div className="overlap-13">
                <img className="image-3" alt="Image" src="/img/section-01-image-01-1.png" />
                <img className="img-5" alt="Footer company logo" src="/img/section-01-image-03-3.png" />
              </div>
              <p className="text-wrapper-102">
                An ed-tech startup founded by people passionate about transforming lives through education, employment
                and employability
              </p>
              <div className="company-links-4">
                <div className="text-wrapper-103">DigitEd</div>
                <div className="text-wrapper-104">About Us</div>
                <div className="text-wrapper-105">Contact Us</div>
              </div>
              <div className="resources-links-3">
                <div className="text-wrapper-103">Products</div>
                <div className="text-wrapper-105">DigitEd Business</div>
                <div className="text-wrapper-106">DigitEd Campus</div>
                <div className="text-wrapper-104">DigitEd</div>
              </div>
              <div className="frame-147">
                <div className="text-wrapper-107">Get in touch</div>
                <div className="frame-142">
                  <div className="frame-148">
                    <img className="footer-icon-email" alt="Footer icon email" src="/img/section-01-image-01-1.png" />
                    <div className="text-wrapper-108">support@digitedlabs.com</div>
                  </div>
                  <div className="frame-149">
                    <img
                      className="footer-icon-address"
                      alt="Footer icon address"
                      src="/img/section-01-image-01-1.png"
                    />
                    <div className="t-hub-gachibowli-4">
                      T-Hub, Gachibowli, <br />
                      Hyderabad, 500032
                    </div>
                  </div>
                </div>
              </div>
              <img className="footer-social-icon" alt="Footer social icon" src="/img/section-01-image-01-1.png" />
              <img className="footer-social-icon-2" alt="Footer social icon" src="/img/section-01-image-01-1.png" />
              <p className="text-wrapper-109">© 2023. All rights reserved</p>
              <img className="vector-6" alt="Vector" src="/img/section-01-image-01-1.png" />
            </div>
          </div>
          <div className="overlap-14">
            <div className="mobile-digited">
              <div className="overlap-15">
                <div className="frame-150">
                  <div className="frame-126">
                    <div className="type-10">Benefits</div>
                  </div>
                  <p className="type-14">
                    Experience the power of <br />
                    collective wisdom &amp; shared experiences
                  </p>
                </div>
                <div className="frame-151">
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-110">Learn &amp; share knowledge actively in your language</p>
                      <p className="text-wrapper-111">
                        Break barriers to your learning journey - learn comfortably in your native language with our
                        platform&#39;s courses available in multiple languages - Telugu, English &amp; Hindi.
                      </p>
                    </div>
                    <img className="section-image-13" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-110">Communicate complex ideas with ease</p>
                      <p className="text-wrapper-111">
                        Recall information better with the help of engaging formats while learning. Share complicated
                        ideas with the community using text, image and even short videos.
                      </p>
                    </div>
                    <img className="section-image-14" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-112">Discover insider tips &amp; tricks from top professionals</p>
                      <p className="text-wrapper-111">
                        Boost your profile and career prospects by enrolling in verified courses from premier
                        institutes. Network with experts and gain a competitive edge.
                      </p>
                      <div className="frame-153">
                        <div className="frame-154">
                          <img className="image-4" alt="Image" src="/img/section-01-image-01-1.png" />
                          <img className="image-5" alt="Image" src="/img/section-01-image-01-1.png" />
                          <img className="image-6" alt="Image" src="/img/section-01-image-01-1.png" />
                        </div>
                        <div className="frame-155">
                          <div className="text-wrapper-113">LEARN MORE</div>
                          <img className="arrow-forward-4" alt="Arrow forward" src="/img/section-01-image-01-1.png" />
                        </div>
                      </div>
                    </div>
                    <img className="section-image-15" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                </div>
              </div>
              <div className="overlap-16">
                <div className="section-main-wrapper">
                  <img className="section-main-8" alt="Section main" src="/img/section-01-image-03-3.png" />
                </div>
                <div className="frame-156">
                  <div className="frame-157">
                    <p className="text-wrapper-93">Built to learn and share knowledge with ease socially!</p>
                    <p className="text-wrapper-114">
                      Join our vibrant community of over 20,000+ learners. Share, collaborate, connect and so much more
                      with DigitEd starting today.
                    </p>
                  </div>
                  <button className="buttons-12">
                    <div className="text-wrapper-91">Join now</div>
                  </button>
                </div>
              </div>
              <div className="div-4">
                <div className="frame-158">
                  <div className="text-wrapper-88">Contact Us</div>
                </div>
              </div>
              <img className="image-7" alt="Image" src="/img/section-01-image-01-1.png" />
              <img className="frame-159" alt="Frame" src="/img/section-01-image-01-1.png" />
              <img className="section-main-9" alt="Section main" src="/img/section-04-main-1.png" />
              <div className="text-container-4">
                <p className="text-wrapper-99">
                  On Digited, you learn from the experts. As you are taking courses, you are really learning from the
                  best instructors in the world.
                </p>
                <img className="frame-160" alt="Frame" src="/img/section-01-image-01-1.png" />
                <div className="frame-143">
                  <div className="frame-161">
                    <img className="ellipse-6" alt="Ellipse" src="/img/section-01-image-01-1.png" />
                  </div>
                  <div className="frame-144">
                    <div className="text-wrapper-100">Rosaline McArthur</div>
                    <div className="text-wrapper-101">Senior Product Manager</div>
                  </div>
                </div>
                <div className="frame-145">
                  <div className="ellipse-4" />
                  <div className="ellipse-5" />
                  <div className="ellipse-4" />
                </div>
              </div>
              <div className="frame-162">
                <div className="overlap-group-6">
                  <img className="image-3" alt="Image" src="/img/section-01-image-01-1.png" />
                  <img className="img-5" alt="Mask group" src="/img/section-01-image-01-1.png" />
                </div>
                <p className="text-wrapper-102">
                  An ed-tech startup founded by people passionate about transforming lives through education, employment
                  and employability
                </p>
                <div className="company-links-4">
                  <div className="text-wrapper-103">DigitEd</div>
                  <div className="text-wrapper-104">About Us</div>
                  <div className="text-wrapper-105">Contact Us</div>
                </div>
                <div className="resources-links-3">
                  <div className="text-wrapper-103">Products</div>
                  <div className="text-wrapper-105">DigitEd Business</div>
                  <div className="text-wrapper-106">DigitEd Campus</div>
                  <div className="text-wrapper-104">DigitEd</div>
                </div>
                <div className="frame-147">
                  <div className="text-wrapper-107">Get in touch</div>
                  <div className="frame-142">
                    <div className="frame-148">
                      <img
                        className="mail-outline-black-4"
                        alt="Mail outline black"
                        src="/img/section-01-image-01-1.png"
                      />
                      <div className="text-wrapper-108">support@digitedlabs.com</div>
                    </div>
                    <div className="frame-149">
                      <img className="place-black-3" alt="Place black" src="/img/section-01-image-01-1.png" />
                      <div className="t-hub-gachibowli-4">
                        T-Hub, Gachibowli, <br />
                        Hyderabad, 500032
                      </div>
                    </div>
                  </div>
                </div>
                <Component3 property1="logo-instagram-1" state="default" />
                <Component3 property1="logo-linkedin-1" state="default" />
                <p className="text-wrapper-109">© 2023. All rights reserved</p>
                <img className="vector-7" alt="Vector" src="/img/section-01-image-01-1.png" />
              </div>
              <div className="overlap-17">
                <div className="rectangle-2" />
                <div className="frame-163">
                  <div className="frame-104">
                    <div className="frame-164">
                      <div className="frame-165">
                        <img
                          className="section-finance"
                          alt="Section finance"
                          src="/img/section-06-finance-and-banking.png"
                        />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Finance &amp; Banking</div>
                          <div className="text-wrapper-116">26 courses</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="img-6" alt="Section medicine" src="/img/section-06-medicine.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Medicine</div>
                          <div className="text-wrapper-116">6 courses</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-104">
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="img-6" alt="Section law" src="/img/section-06-law.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Law</div>
                          <div className="text-wrapper-116">12 courses</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="img-6" alt="Section science" src="/img/section-06-science.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Science</div>
                          <div className="text-wrapper-116">21 courses</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-104">
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="img-6" alt="Section business" src="/img/section-06-business-management.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Business Management</div>
                          <div className="text-wrapper-116">26 courses</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="section-railways" alt="Section railways" src="/img/section-06-railways.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Railways</div>
                          <div className="text-wrapper-116">6 courses</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-104">
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="section" alt="Section" src="/img/section-06-engineering.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Engineering</div>
                          <div className="text-wrapper-116">12 courses</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-164">
                      <div className="frame-165">
                        <img className="section-2" alt="Section" src="/img/section-06-journalism-and-media.png" />
                        <div className="frame-166">
                          <div className="text-wrapper-115">Journalism &amp; Media</div>
                          <div className="text-wrapper-116">21 courses</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-167">
                  <div className="frame-126">
                    <div className="type-10">Start learning today</div>
                  </div>
                  <div className="type-15">Explore popular categories</div>
                </div>
                <div className="frame-168">
                  <div className="element-2">
                    <img className="section-icon-5" alt="Section icon" src="/img/section-05-icon-01-explore.svg" />
                    <div className="frame-169">
                      <div className="type-16">Explore</div>
                      <p className="text-wrapper-117">
                        Learn faster with 1000+ courses materials, quizzes and daily learning sent to you everyday.
                      </p>
                    </div>
                  </div>
                  <div className="element-3">
                    <img className="section-icon-5" alt="Section icon" src="/img/section-05-icon-03-challenge.svg" />
                    <div className="frame-170">
                      <div className="type-16">Challenge &amp; Analyze</div>
                      <p className="text-wrapper-118">
                        Challenge your peers, track where you stand with leaderboards, and use insights to improve on
                        the go.
                      </p>
                    </div>
                  </div>
                  <div className="element-3">
                    <img className="section-icon-5" alt="Section icon" src="/img/section-05-icon-02-learn.svg" />
                    <div className="frame-120">
                      <div className="type-16">Learn &amp; Create</div>
                      <p className="text-wrapper-118">
                        Take unlimited free quiz &amp; access study materials. Challenge your knowledge in-depth by
                        creating and adding materials with the world.
                      </p>
                    </div>
                  </div>
                  <div className="element-3">
                    <img className="section-icon-5" alt="Section icon" src="/img/section-05-icon-04-updated.svg" />
                    <div className="frame-170">
                      <div className="type-16">Stay Updated</div>
                      <p className="text-wrapper-118">
                        Daily news current affairs exam and job alerts Lorem ipsum dolor sit.
                      </p>
                    </div>
                  </div>
                </div>
                <img className="section-main-10" alt="Section main" src="/img/section-05-main.png" />
              </div>
              <p className="limitless-knowledge">
                Limitless knowledge <br />
                now in your pocket!
              </p>
              <div className="frame-171">
                <div className="frame-172">
                  <div className="frame-152">
                    <div className="text-wrapper-119">Discover what’s new today</div>
                    <p className="stay-up-to-date-with">
                      Stay up-to-date with the latest hashtags &amp; join the conversation. Download the app now!
                    </p>
                  </div>
                </div>
                <div className="frame-173">
                  <div className="text-wrapper-120">#tspsc</div>
                </div>
                <div className="frame-174">
                  <div className="text-wrapper-120">#telanganamovement</div>
                </div>
                <div className="frame-175">
                  <div className="text-wrapper-120">#indianhistory</div>
                </div>
                <div className="frame-176">
                  <div className="text-wrapper-120">#historyoftelangana</div>
                </div>
                <div className="frame-177">
                  <div className="text-wrapper-120">#indiangeography</div>
                </div>
                <div className="frame-178">
                  <div className="text-wrapper-120">#telanganaquiz</div>
                </div>
              </div>
            </div>
            <div className="mobile-about-us">
              <div className="overlap-18">
                <img className="section-BG-main-2" alt="Section BG main" src="/img/section-04-bg-main.svg" />
                <div className="rectangle-3" />
                <div className="frame-179">
                  <div className="text-wrapper-121">Our Mission</div>
                  <div className="frame-111">
                    <p className="text-wrapper-122">
                      To build a next-generation e-learning platform for competitive exam preparation and converge it
                      into an ecosystem for learning.
                    </p>
                    <p className="text-wrapper-123">
                      We believe such convergence is necessary for content to innovate faster and make its access more
                      affordable. It does so by converging data touch points to create an intelligent platform that not
                      just helps individuals to learn better, but improvises itself imbibing learner with meta cognitive
                      skills necessary to self-direct.
                      <br />
                      DigitEd as it is envisioned today will be a base with powerful integrations to make a universally
                      accessible learning platform that delivers learning outcomes to its learners, giving them the
                      choice, making education truly accessible, affordable, equitable and is lifelong.
                    </p>
                  </div>
                </div>
                <div className="frame-180">
                  <div className="type-17">Our Expertise</div>
                  <div className="frame-116">
                    <div className="frame-117">
                      <img
                        className="section-icon-6"
                        alt="Section icon"
                        src="/img/section-04-icon-design-and-development.png"
                      />
                      <div className="frame-118">
                        <div className="text-wrapper-124">Design &amp; Development</div>
                        <p className="text-wrapper-85">
                          We love to develop products like Geneo, Good Worker and DigitEd that sustainably transform the
                          lives of millions.
                        </p>
                      </div>
                    </div>
                    <div className="frame-119">
                      <img
                        className="section-icon-6"
                        alt="Section icon"
                        src="/img/section-04-icon-marketing-and-scale.png"
                      />
                      <div className="frame-120">
                        <div className="text-wrapper-124">Marketing &amp; Scale</div>
                        <p className="text-wrapper-86">
                          Product is as good as the meaning the end-user derives from it. We not just build products but
                          market it at scale.
                        </p>
                      </div>
                    </div>
                    <div className="frame-119">
                      <img className="section-icon-data" alt="Section icon data" src="/img/section-01-image-03-3.png" />
                      <div className="frame-120">
                        <div className="text-wrapper-124">Data &amp; Cloud Technologies</div>
                        <p className="text-wrapper-87">
                          Design ethos of scalable solutions led us to develop in-house expertise in the technologies of
                          the future.
                        </p>
                      </div>
                    </div>
                    <div className="frame-119">
                      <img className="section-icon-7" alt="Section icon" src="/img/section-01-image-03-3.png" />
                      <div className="frame-120">
                        <div className="text-wrapper-124">Subject Matter Expertise</div>
                        <p className="text-wrapper-87">
                          To keep Product in sync with end-user, subject expertise is embedded in design DNA of every
                          product feature.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-19">
                <img className="section-BG-image-3" alt="Section BG image" src="/img/section-01-bg-image.svg" />
                <div className="rectangle-4" />
                <img className="image-8" alt="Image" src="/img/image-4-3.png" />
                <div className="frame-181">
                  <div className="text-wrapper-88">Contact Us</div>
                </div>
                <div className="frame-182">
                  <div className="frame-183">
                    <div className="frame-184">
                      <div className="type-18">Who are we?</div>
                    </div>
                    <div className="frame-185">
                      <p className="text-wrapper-125">
                        We are passionate about transforming lives through education, employment and employability
                      </p>
                    </div>
                  </div>
                  <div className="buttons-13">
                    <div className="text-wrapper-91">Let’s Connect</div>
                  </div>
                </div>
                <div className="frame-186">
                  <div className="text-wrapper-121">Our Vision</div>
                  <div className="frame-111">
                    <p className="text-wrapper-122">
                      To build a learning ecosystem for the 21st century world. A future where technology will
                      seamlessly integrate with the learning process.
                    </p>
                    <p className="text-wrapper-123">
                      It will be a symbiotic environment where people interact with the content, technologies, and data
                      that surround them. The ecosystem would transform the present fragmented and unequitable learning
                      spaces into a more diverse, rich and interconnected whole. The transformation that we believe is
                      key to humanity’s next stage of evolution.
                      <br />
                      We at DigitEd are committed to invest and build such a future of learning. A future where there
                      are no hindrances for an individual wanting to learn. A future where learning redefines the
                      boundaries of human understanding. A future that gets shaped as we learn.
                    </p>
                  </div>
                </div>
              </div>
              <img className="frame-187" alt="Frame" src="/img/section-01-image-01-1.png" />
              <div className="frame-188">
                <div className="overlap-group-7">
                  <img className="image-3" alt="Image" src="/img/section-01-image-01-1.png" />
                  <img className="img-5" alt="Mask group" src="/img/section-01-image-01-1.png" />
                </div>
                <p className="text-wrapper-102">
                  An ed-tech startup founded by people passionate about transforming lives through education, employment
                  and employability
                </p>
                <div className="company-links-4">
                  <div className="text-wrapper-103">DigitEd</div>
                  <div className="text-wrapper-104">About Us</div>
                  <div className="text-wrapper-105">Contact Us</div>
                </div>
                <div className="resources-links-3">
                  <div className="text-wrapper-103">Products</div>
                  <div className="text-wrapper-105">DigitEd Business</div>
                  <div className="text-wrapper-106">DigitEd Campus</div>
                  <div className="text-wrapper-104">DigitEd</div>
                </div>
                <div className="frame-147">
                  <div className="text-wrapper-107">Get in touch</div>
                  <div className="frame-142">
                    <div className="frame-148">
                      <img
                        className="mail-outline-black-5"
                        alt="Mail outline black"
                        src="/img/section-01-image-01-1.png"
                      />
                      <div className="text-wrapper-108">support@digitedlabs.com</div>
                    </div>
                    <div className="frame-149">
                      <img className="place-black-4" alt="Place black" src="/img/section-01-image-01-1.png" />
                      <div className="t-hub-gachibowli-4">
                        T-Hub, Gachibowli, <br />
                        Hyderabad, 500032
                      </div>
                    </div>
                  </div>
                </div>
                <Component3 property1="logo-instagram-1" state="default" />
                <Component3 property1="logo-linkedin-1" state="default" />
                <p className="text-wrapper-109">© 2023. All rights reserved</p>
                <img className="vector-8" alt="Vector" src="/img/section-01-image-01-1.png" />
              </div>
              <div className="CT-as-4">
                <p className="text-wrapper-126">Ready for your next step?</p>
                <p className="text-wrapper-127">Let us help you get there</p>
                <div className="buttons-14">
                  <div className="text-wrapper-128">Get in touch</div>
                </div>
              </div>
              <p className="text-wrapper-129">
                At DigitEd Labs, we pride ourselves on having a talented and diverse team of professionals who are
                dedicated to delivering the best possible experience for our customers.
              </p>
              <div className="text-wrapper-130">Meet our Team</div>
              <div className="overlap-20">
                <div className="buttons-15">
                  <div className="text-wrapper-91">View careers</div>
                </div>
                <div className="frame-189">
                  <div className="text-wrapper-131">Join Our Team</div>
                  <p className="text-wrapper-132">
                    Check out our current job openings! We offer a great work environment, competitive compensation and
                    benefits, and plenty of opportunities to learn and grow.
                  </p>
                </div>
              </div>
              <div className="frame-190">
                <img className="section-image-16" alt="Section image" src="/img/section-01-image-03-3.png" />
                <img className="section-image-17" alt="Section image" src="/img/section-01-image-03-3.png" />
                <img className="section-image-18" alt="Section image" src="/img/section-01-image-03-3.png" />
                <img className="section-image-19" alt="Section image" src="/img/section-01-image-03-3.png" />
              </div>
            </div>
            <div className="mobile-digited-2">
              <div className="overlap-21">
                <div className="overlap-22">
                  <img className="section-BG-image-4" alt="Section BG image" src="/img/section-01-image-03-3.png" />
                  <img className="image-9" alt="Image" src="/img/section-01-image-01-1.png" />
                </div>
                <img className="frame-191" alt="Frame" src="/img/section-01-image-01-1.png" />
              </div>
              <div className="overlap-23">
                <div className="frame-150">
                  <div className="frame-126">
                    <div className="type-10">Benefits</div>
                  </div>
                  <p className="type-19">
                    <span className="text-wrapper-133">
                      Start teaching &amp; make an impact right away Turn your expertise into profit by{" "}
                    </span>
                    <span className="text-wrapper-134">10x</span>
                    <span className="text-wrapper-133">.</span>
                  </p>
                </div>
                <div className="frame-151">
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-110">Readily available community of learners</p>
                      <p className="text-wrapper-111">
                        Find your audience from our community of over 20,000 learners and gain exposure. Establish your
                        credibility and build your tribe.
                      </p>
                    </div>
                    <img className="section-image-20" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-110">Launch courses free of cost</p>
                      <p className="text-wrapper-111">
                        Share your valuable knowledge with the world and make a positive impact. Use metrics to test out
                        different teaching methodologies and track the ones that bring in impactful results.
                      </p>
                    </div>
                    <img className="section-image-21" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-112">Get charged only after you generate revenue</p>
                      <p className="text-wrapper-111">
                        We succeed only when you do (10% cut). Launch your courses without an upfront cost. Experiment
                        with different pricing models &amp; strategies and learn what works best for your course and
                        your audience.
                      </p>
                      <div className="frame-153">
                        <div className="frame-155">
                          <div className="text-wrapper-113">LEARN MORE</div>
                          <img className="arrow-forward-5" alt="Arrow forward" src="/img/section-01-image-01-1.png" />
                        </div>
                      </div>
                    </div>
                    <img className="section-image-22" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                </div>
              </div>
              <div className="overlap-24">
                <div className="div-4" />
                <div className="frame-122">
                  <div className="text-wrapper-88">Contact Us</div>
                </div>
              </div>
              <div className="overlap-25">
                <div className="text-container-5">
                  <p className="text-wrapper-99">
                    DigitEd made it simple for me to manage my courses and interact with students. I’m super impressed!
                  </p>
                  <img className="frame-192" alt="Frame" src="/img/frame-226-1.svg" />
                  <div className="frame-143">
                    <div className="frame-161">
                      <img className="ellipse-7" alt="Ellipse" src="/img/ellipse-21-1.png" />
                    </div>
                    <div className="frame-144">
                      <div className="text-wrapper-100">Rosaline McArthur</div>
                      <div className="text-wrapper-101">Senior Product Manager</div>
                    </div>
                  </div>
                  <div className="frame-145">
                    <div className="ellipse-4" />
                    <div className="ellipse-5" />
                    <div className="ellipse-4" />
                  </div>
                </div>
                <p className="text-wrapper-135">All features for one price.</p>
                <div className="overlap-26">
                  <p className="text-wrapper-136">Get access to a business account at an affordable price.</p>
                  <img className="section-main-11" alt="Section main" src="/img/section-04-main.png" />
                </div>
              </div>
              <div className="frame-193">
                <div className="overlap-group-8">
                  <img className="image-3" alt="Image" src="/img/section-01-image-01-1.png" />
                  <img className="img-5" alt="Mask group" src="/img/section-01-image-01-1.png" />
                </div>
                <p className="text-wrapper-102">
                  An ed-tech startup founded by people passionate about transforming lives through education, employment
                  and employability
                </p>
                <div className="company-links-5">
                  <div className="text-wrapper-103">DigitEd</div>
                  <div className="text-wrapper-104">About Us</div>
                  <div className="text-wrapper-105">Contact Us</div>
                </div>
                <div className="resources-links-3">
                  <div className="text-wrapper-103">Products</div>
                  <div className="text-wrapper-105">DigitEd Business</div>
                  <div className="text-wrapper-106">DigitEd Campus</div>
                  <div className="text-wrapper-104">DigitEd</div>
                </div>
                <div className="frame-147">
                  <div className="text-wrapper-107">Get in touch</div>
                  <div className="frame-142">
                    <div className="frame-148">
                      <img
                        className="mail-outline-black-6"
                        alt="Mail outline black"
                        src="/img/section-01-image-01-1.png"
                      />
                      <div className="text-wrapper-108">support@digitedlabs.com</div>
                    </div>
                    <div className="frame-149">
                      <img className="place-black-5" alt="Place black" src="/img/section-01-image-01-1.png" />
                      <div className="t-hub-gachibowli-4">
                        T-Hub, Gachibowli, <br />
                        Hyderabad, 500032
                      </div>
                    </div>
                  </div>
                </div>
                <Component3 property1="logo-instagram-1" state="default" />
                <Component3 property1="logo-linkedin-1" state="default" />
                <p className="text-wrapper-109">© 2023. All rights reserved</p>
                <img className="vector-9" alt="Vector" src="/img/section-01-image-01-1.png" />
              </div>
              <div className="CT-as-5">
                <p className="text-wrapper-126">Ready for your next step?</p>
                <p className="text-wrapper-127">Let us help you get there</p>
                <div className="buttons-14">
                  <div className="text-wrapper-128">Get in touch</div>
                </div>
              </div>
              <div className="frame-194">
                <div className="frame-126">
                  <div className="type-10">Features</div>
                </div>
                <p className="type-14">Manage your courses more effectively with our state-of-the-art infrastructure</p>
              </div>
              <div className="frame-195">
                <div className="frame-196">
                  <img className="section-icon-8" alt="Section icon" src="/img/section-03-icon-unlimited-storage.svg" />
                  <div className="frame-197">
                    <div className="type-20">Unlimited Storage &amp; Bandwidth</div>
                    <p className="text-wrapper-137">
                      Create &amp; Stream courses in high definition(HD) with rich multi-media content at no additional
                      cost.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-8" alt="Section icon" src="/img/section-03-icon-modern-cloud-tech.svg" />
                  <div className="frame-197">
                    <div className="type-20">Modern Cloud Technologies</div>
                    <p className="text-wrapper-137">
                      Designed for scale with zero downtime, your popular course can handle any number of students at
                      any given time.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-8" alt="Section icon" src="/img/section-03-icon-premium-platform.svg" />
                  <div className="frame-197">
                    <p className="type-20">Premium Platform with Lifetime Upgrades</p>
                    <p className="text-wrapper-137">
                      To make your courses stand out as a premium offering with up-to-date content &amp; technology.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-8" alt="Section icon" src="/img/section-03-icon-course-marketing.svg" />
                  <div className="frame-197">
                    <p className="type-20">Course Marketing &amp; Institute Branding</p>
                    <p className="text-wrapper-137">
                      We brand your Institute &amp; help your courses to be listed on top of the searches on Google with
                      zero effort.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img
                    className="section-icon-8"
                    alt="Section icon"
                    src="/img/section-03-icon-powerful-integrations.svg"
                  />
                  <div className="frame-197">
                    <div className="type-20">Powerful Integrations &amp; Capabilities</div>
                    <p className="text-wrapper-137">
                      Schedule Live Classes, Send Smart Notifications, Seamlessly review &amp; update Course content.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img
                    className="section-icon-8"
                    alt="Section icon"
                    src="/img/section-03-icon-customer-relationship-manager.svg"
                  />
                  <div className="frame-197">
                    <div className="type-20">Customer Relationship Manager</div>
                    <p className="text-wrapper-137">
                      A dedicated professional to smoothen the onboarding experience and help you launch a new course.
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-198">
                <div className="frame-185">
                  <p className="text-wrapper-138">Start teaching &amp; share your expertise with the world</p>
                  <p className="text-wrapper-95">
                    Build your own courses, publish quizzes and tests and manage your audience.
                  </p>
                </div>
                <button className="buttons-16">
                  <div className="text-wrapper-91">Join now</div>
                </button>
              </div>
            </div>
            <div className="mobile-digited-3">
              <div className="overlap-27">
                <div className="rectangle-5" />
                <img className="section-main-12" alt="Section main" src="/img/section-01-main.png" />
                <div className="rectangle-6" />
                <img className="image-10" alt="Image" src="/img/image-4-1.png" />
                <img className="frame-199" alt="Frame" src="/img/frame-163051-1.svg" />
                <div className="frame-200">
                  <div className="text-wrapper-88">Contact Us</div>
                </div>
                <div className="frame-201">
                  <div className="frame-126">
                    <div className="type-10">Benefits</div>
                  </div>
                  <p className="type-14">Use DigitEd to simplify your life as an educator</p>
                </div>
                <div className="frame-202">
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-110">Reach every student no matter where they are</p>
                      <p className="text-wrapper-111">
                        Effortlessly deliver course materials &amp; increase accessibility of educational resources.
                        Communicate with your students at lightning speed and ensure no one is left behind.
                      </p>
                      <div className="frame-153">
                        <div className="frame-155">
                          <div className="text-wrapper-113">BOOK A DEMO</div>
                          <img
                            className="arrow-forward-6"
                            alt="Arrow forward"
                            src="/img/arrow-forward-outline-1-2.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <img className="section-image-23" alt="Section image" src="/img/section-02-image-01.png" />
                  </div>
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-110">Collaborate seamlessly with your department</p>
                      <p className="text-wrapper-111">
                        Stay connected with your colleagues, share ideas and resources, and achieve your goals together!
                        Teamwork makes the dream work.
                      </p>
                      <div className="frame-153">
                        <div className="frame-155">
                          <div className="text-wrapper-113">BOOK A DEMO</div>
                          <img className="arrow-forward-7" alt="Arrow forward" src="/img/section-01-image-01-1.png" />
                        </div>
                      </div>
                    </div>
                    <img className="section-image-24" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                  <div className="frame-111">
                    <div className="frame-152">
                      <p className="text-wrapper-112">Embrace the future of education with confidence</p>
                      <p className="text-wrapper-111">
                        LMS ecosystem - Enhanced communication and collaboration between teachers, administrators, and
                        students. Get data-driven insights and make better decisions.
                      </p>
                      <div className="frame-153">
                        <div className="frame-155">
                          <div className="text-wrapper-113">BOOK A DEMO</div>
                          <img className="arrow-forward-8" alt="Arrow forward" src="/img/section-01-image-01-1.png" />
                        </div>
                      </div>
                    </div>
                    <img className="section-image-25" alt="Section image" src="/img/section-01-image-03-3.png" />
                  </div>
                </div>
                <div className="frame-203">
                  <div className="frame-185">
                    <p className="text-wrapper-138">Deliver better learning and build brighter futures</p>
                    <p className="text-wrapper-95">
                      Enable your institute and employees with DigitEd campus and&nbsp;&nbsp;take your students and
                      employees future to new heights.
                    </p>
                  </div>
                  <div className="buttons-17">
                    <div className="text-wrapper-91">Book a demo</div>
                  </div>
                </div>
              </div>
              <div className="text-container-6">
                <p className="text-wrapper-99">
                  DigitEd made it simple for me to manage my courses and interact with students. I’m super impressed!
                </p>
                <img className="frame-204" alt="Frame" src="/img/section-01-image-01-1.png" />
                <div className="frame-143">
                  <div className="frame-161">
                    <img className="ellipse-8" alt="Ellipse" src="/img/section-01-image-01-1.png" />
                  </div>
                  <div className="frame-144">
                    <div className="text-wrapper-100">Rosaline McArthur</div>
                    <div className="text-wrapper-101">Senior Product Manager</div>
                  </div>
                </div>
                <div className="frame-145">
                  <div className="ellipse-4" />
                  <div className="ellipse-5" />
                  <div className="ellipse-4" />
                </div>
              </div>
              <div className="frame-205">
                <div className="overlap-group-9">
                  <img className="image-3" alt="Image" src="/img/section-01-image-01-1.png" />
                  <img className="img-5" alt="Mask group" src="/img/section-01-image-01-1.png" />
                </div>
                <p className="text-wrapper-102">
                  An ed-tech startup founded by people passionate about transforming lives through education, employment
                  and employability
                </p>
                <div className="company-links-4">
                  <div className="text-wrapper-103">DigitEd</div>
                  <div className="text-wrapper-104">About Us</div>
                  <div className="text-wrapper-105">Contact Us</div>
                </div>
                <div className="resources-links-3">
                  <div className="text-wrapper-103">Products</div>
                  <div className="text-wrapper-105">DigitEd Business</div>
                  <div className="text-wrapper-106">DigitEd Campus</div>
                  <div className="text-wrapper-104">DigitEd</div>
                </div>
                <div className="frame-147">
                  <div className="text-wrapper-107">Get in touch</div>
                  <div className="frame-142">
                    <div className="frame-148">
                      <img
                        className="mail-outline-black-7"
                        alt="Mail outline black"
                        src="/img/section-01-image-01-1.png"
                      />
                      <div className="text-wrapper-108">support@digitedlabs.com</div>
                    </div>
                    <div className="frame-149">
                      <img className="place-black-6" alt="Place black" src="/img/section-01-image-01-1.png" />
                      <div className="t-hub-gachibowli-4">
                        T-Hub, Gachibowli, <br />
                        Hyderabad, 500032
                      </div>
                    </div>
                  </div>
                </div>
                <Component3 property1="logo-instagram-1" state="default" />
                <Component3 property1="logo-linkedin-1" state="default" />
                <p className="text-wrapper-109">© 2023. All rights reserved</p>
                <img className="vector-10" alt="Vector" src="/img/section-01-image-01-1.png" />
              </div>
              <img className="section-BG-image-5" alt="Section BG image" src="/img/section-01-image-03-3.png" />
              <p className="text-wrapper-139">See what DigitEd can do for you</p>
              <p className="find-out-how-it-can-2">
                Find out how it can elevate your teaching experience &amp; unlock the full potential of your classroom.
              </p>
              <div className="buttons-18">
                <div className="text-wrapper-128">Get in touch</div>
              </div>
              <div className="frame-206">
                <div className="frame-126">
                  <div className="type-10">Features</div>
                </div>
                <div className="type-14">Streamline your teaching experience</div>
              </div>
              <div className="frame-207">
                <div className="frame-196">
                  <img className="section-icon-9" alt="Section icon" src="/img/section-01-image-01-1.png" />
                  <div className="frame-197">
                    <p className="type-20">Customize LMS to fit your requirements</p>
                    <p className="text-wrapper-137">
                      Get a tailored learning experience that meets the needs of your students and teachers, without
                      having to compromise on functionality or usability.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-10" alt="Section icon" src="/img/section-01-image-01-1.png" />
                  <div className="frame-197">
                    <div className="type-20">Improved organization and management</div>
                    <p className="text-wrapper-137">
                      DigitEd streamlines teacher and administrator workflows by organizing course materials,
                      assignments, and records in one location.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-11" alt="Section icon" src="/img/section-01-image-01-1.png" />
                  <div className="frame-197">
                    <p className="type-20">Monitor student progress &amp; engagement</p>
                    <p className="text-wrapper-137">
                      Real-time tracking of student progress and performance, allowing for personalized learning
                      experiences and targeted interventions.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-12" alt="Section icon" src="/img/section-01-image-01-1.png" />
                  <div className="frame-197">
                    <p className="type-20">Simple grading &amp; assessment process</p>
                    <p className="text-wrapper-137">
                      Automated grading and assessment process, saving time for teachers and providing immediate
                      feedback to students.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-13" alt="Section icon" src="/img/section-01-image-01-1.png" />
                  <div className="frame-197">
                    <p className="type-20">Reduce cost &amp; environmental impact</p>
                    <p className="text-wrapper-137">
                      DigitEd reduces costs associated with traditional paper-based teaching methods and has a lower
                      impact.
                    </p>
                  </div>
                </div>
                <div className="frame-196">
                  <img className="section-icon-14" alt="Section icon" src="/img/section-01-image-01-1.png" />
                  <div className="frame-197">
                    <div className="type-20">Staff training</div>
                    <p className="text-wrapper-137">
                      Effortlessly train and onboard staff members to DigitEd platform, saving time and providing a
                      seamless transition to the new ecosystem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-contact-us">
              <div className="overlap-28">
                <div className="rectangle-7" />
                <img className="image-11" alt="Image" src="/img/image-4.png" />
                <img className="frame-208" alt="Frame" src="/img/frame-163051.svg" />
                <div className="frame-209">
                  <div className="text-wrapper-88">Contact Us</div>
                </div>
                <div className="frame-210">
                  <div className="overlap-group-10">
                    <img className="image-12" alt="Image" src="/img/image-3.png" />
                    <img className="mask-group-3" alt="Mask group" src="/img/mask-group-1.png" />
                  </div>
                  <p className="text-wrapper-102">
                    An ed-tech startup founded by people passionate about transforming lives through education,
                    employment and employability
                  </p>
                  <div className="company-links-4">
                    <div className="text-wrapper-103">DigitEd</div>
                    <div className="text-wrapper-104">About Us</div>
                    <div className="text-wrapper-105">Contact Us</div>
                  </div>
                  <div className="resources-links-3">
                    <div className="text-wrapper-103">Products</div>
                    <div className="text-wrapper-105">DigitEd Business</div>
                    <div className="text-wrapper-106">DigitEd Campus</div>
                    <div className="text-wrapper-104">DigitEd</div>
                  </div>
                  <div className="frame-147">
                    <div className="text-wrapper-107">Get in touch</div>
                    <div className="frame-142">
                      <div className="frame-148">
                        <img
                          className="mail-outline-black-8"
                          alt="Mail outline black"
                          src="/img/section-01-image-01-1.png"
                        />
                        <div className="text-wrapper-108">support@digitedlabs.com</div>
                      </div>
                      <div className="frame-149">
                        <img className="place-black-7" alt="Place black" src="/img/section-01-image-01-1.png" />
                        <div className="t-hub-gachibowli-4">
                          T-Hub, Gachibowli, <br />
                          Hyderabad, 500032
                        </div>
                      </div>
                    </div>
                  </div>
                  <Component3 property1="logo-instagram-1" state="default" />
                  <Component3 property1="logo-linkedin-1" state="default" />
                  <p className="text-wrapper-109">© 2023. All rights reserved</p>
                  <img className="vector-11" alt="Vector" src="/img/section-01-image-01-1.png" />
                </div>
                <img className="section-BG-main-3" alt="Section BG main" src="/img/section-01-bg-main.png" />
                <div className="frame-211">
                  <p className="text-wrapper-140">Ready to take your learner’s journey to the next level?</p>
                  <p className="text-wrapper-141">
                    Get in touch with our team. Learn more about Digited solutions. We will help you find the right
                    products and pricing for your business.
                  </p>
                </div>
                <div className="frame-212">
                  <div className="frame-213">
                    <div className="frame-114">
                      <div className="frame-214">
                        <div className="frame-215">
                          <p className="div-5">
                            <span className="text-wrapper-64">Full Name </span>
                            <span className="text-wrapper-65">*</span>
                          </p>
                        </div>
                        <div className="frame-215">
                          <p className="div-5">
                            <span className="text-wrapper-64">Email </span>
                            <span className="text-wrapper-65">*</span>
                          </p>
                        </div>
                        <div className="frame-215">
                          <div className="text-wrapper-142">Phone number</div>
                        </div>
                        <div className="frame-216">
                          <div className="text-wrapper-142">Enter your message</div>
                        </div>
                      </div>
                      <div className="buttons-19">
                        <div className="text-wrapper-91">Talk to us now!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-29">
            <img className="mask-group-4" alt="Mask group" src="/img/mask-group.png" />
          </div>
        </div>
        <div className="frame-217">
          <div className="frame-218">
            <div className="heading">
              <div className="text-wrapper-143">Digited Labs</div>
            </div>
            <p className="text-wrapper-144">Redesigning the Web experience for Ed-tech</p>
          </div>
          <div className="group-3">
            <div className="UIX-wrapper">
              <div className="UIX">
                <div className="frame-219">
                  <div className="frame-220">
                    <div className="group-4">
                      <div className="overlap-group-11">
                        <img className="line" alt="Line" src="/img/line-18.svg" />
                        <img className="line-2" alt="Line" src="/img/line-19.svg" />
                      </div>
                    </div>
                    <div className="text-wrapper-145">Ed-tech</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-146">Industry</div>
          </div>
        </div>
        <div className="frame-221">
          <img className="rectangle-8" alt="Rectangle" src="/img/section-01-image-03-3.png" />
          <div className="overlap-30">
            <img className="home-page-2" alt="Home page" src="/img/home-page-1.png" />
            <img className="home-page-3" alt="Home page" src="/img/home-page.png" />
          </div>
        </div>
        <div className="frame-222">
          <p className="we-are-in-love-with">
            We are in love with the new design for our website. The redesign resulted in significant improvements in
            user engagement, experience, and overall satisfaction. We had a great experience working with
            99xStudio.&#34;
          </p>
          <div className="frame-36">
            <div className="frame-223">
              <div className="frame-224">
                <div className="text-wrapper-147">Sumanth Reddy</div>
                <div className="text-wrapper-148">Co-Founder, DigitEd Labs</div>
              </div>
            </div>
            <a
              className="text-wrapper-149"
              href="https://clutch.co/profile/99xstudio#review-2187574"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read the complete review
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
