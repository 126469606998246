/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Component = ({
  property1,
  className,
  divClassName,
  text = "Home",
  expandMoreBlackClassName,
  expandMoreBlack = "/img/expand-more-black-24dp-1-1.svg",
  hasExpandMoreBlack = true,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-137",
  });

  return (
    <div
      className={`component ${state.property1} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className={`home ${divClassName}`}>{text}</div>
      {hasExpandMoreBlack && (
        <img
          className={`expand-more-black ${expandMoreBlackClassName}`}
          alt="Expand more black"
          src={
            state.property1 === "frame-140"
              ? "/img/expand-more-black-24dp-1-1-6.svg"
              : state.property1 === "frame-141"
              ? "/img/expand-more-black-24dp-1-1-5.svg"
              : expandMoreBlack
          }
        />
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "frame-137") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "frame-140",
        };
    }
  }

  if (state.property1 === "frame-140") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "frame-137",
        };

      case "click":
        return {
          property1: "frame-141",
        };
    }
  }

  if (state.property1 === "frame-141") {
    switch (action) {
      case "click":
        return {
          property1: "frame-137",
        };
    }
  }

  return state;
}

Component.propTypes = {
  property1: PropTypes.oneOf(["frame-141", "frame-137", "frame-140"]),
  text: PropTypes.string,
  expandMoreBlack: PropTypes.string,
  hasExpandMoreBlack: PropTypes.bool,
};
