/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1LogoInstagram1StateDefault = ({ className }) => {
  return (
    <svg
      className={`property-1-logo-instagram-1-state-default ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.3748 3.24984C17.5342 3.25331 18.6451 3.71539 19.4648 4.53517C20.2846 5.35495 20.7467 6.46582 20.7502 7.62516V16.3748C20.7467 17.5342 20.2846 18.6451 19.4648 19.4648C18.6451 20.2846 17.5342 20.7467 16.3748 20.7502H7.62516C6.46582 20.7467 5.35495 20.2846 4.53517 19.4648C3.71539 18.6451 3.25331 17.5342 3.24984 16.3748V7.62516C3.25331 6.46582 3.71539 5.35495 4.53517 4.53517C5.35495 3.71539 6.46582 3.25331 7.62516 3.24984H16.3748ZM16.3748 1.5H7.62516C4.25625 1.5 1.5 4.25625 1.5 7.62516V16.3748C1.5 19.7437 4.25625 22.5 7.62516 22.5H16.3748C19.7437 22.5 22.5 19.7437 22.5 16.3748V7.62516C22.5 4.25625 19.7437 1.5 16.3748 1.5Z"
        fill="#3D3D3D"
        fillOpacity="0.6"
      />
      <path
        className="path"
        d="M17.6873 7.62515C17.4278 7.62515 17.174 7.54818 16.9582 7.40396C16.7423 7.25974 16.5741 7.05475 16.4748 6.81492C16.3754 6.5751 16.3494 6.3112 16.4001 6.0566C16.4507 5.802 16.5757 5.56813 16.7593 5.38458C16.9428 5.20102 17.1767 5.07602 17.4313 5.02537C17.6859 4.97473 17.9498 5.00072 18.1896 5.10006C18.4294 5.1994 18.6344 5.36763 18.7786 5.58347C18.9229 5.79931 18.9998 6.05306 18.9998 6.31265C19.0002 6.48512 18.9665 6.65596 18.9007 6.81536C18.8349 6.97477 18.7382 7.11961 18.6163 7.24156C18.4943 7.36351 18.3495 7.46017 18.1901 7.526C18.0306 7.59183 17.8598 7.62552 17.6873 7.62515ZM12 8.49984C12.6923 8.49984 13.369 8.70512 13.9446 9.08972C14.5202 9.47433 14.9688 10.021 15.2337 10.6605C15.4986 11.3001 15.568 12.0039 15.4329 12.6828C15.2978 13.3618 14.9645 13.9855 14.475 14.475C13.9855 14.9645 13.3618 15.2978 12.6828 15.4329C12.0039 15.568 11.3001 15.4986 10.6606 15.2337C10.021 14.9688 9.47433 14.5202 9.08973 13.9446C8.70513 13.369 8.49985 12.6923 8.49985 12C8.50084 11.072 8.86992 10.1823 9.52611 9.52611C10.1823 8.86992 11.072 8.50083 12 8.49984ZM12 6.75C10.9617 6.75 9.94662 7.0579 9.08326 7.63478C8.2199 8.21166 7.54699 9.0316 7.14963 9.99091C6.75227 10.9502 6.64831 12.0058 6.85088 13.0242C7.05345 14.0426 7.55347 14.9781 8.28769 15.7123C9.02192 16.4465 9.95738 16.9465 10.9758 17.1491C11.9942 17.3517 13.0498 17.2477 14.0091 16.8504C14.9684 16.453 15.7883 15.7801 16.3652 14.9167C16.9421 14.0534 17.25 13.0383 17.25 12C17.25 10.6076 16.6969 9.27225 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75Z"
        fill="#3D3D3D"
        fillOpacity="0.6"
      />
    </svg>
  );
};
