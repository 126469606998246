/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Component38 = ({ className }) => {
  return (
    <svg
      className={`component-3-8 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 15 14"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.5588 1.09961H2.76045C2.22505 1.09961 1.75726 1.48484 1.75726 2.01395V11.834C1.75726 12.366 2.22505 12.8401 2.76045 12.8401H12.5559C13.0942 12.8401 13.4977 12.3629 13.4977 11.834V2.01395C13.5009 1.48484 13.0942 1.09961 12.5588 1.09961ZM5.39655 10.8859H3.71462V5.65639H5.39655V10.8859ZM4.61377 4.86128H4.60171C4.06343 4.86128 3.71488 4.46059 3.71488 3.95899C3.71488 3.44823 4.0726 3.05697 4.62294 3.05697C5.17327 3.05697 5.51003 3.44535 5.52208 3.95899C5.52182 4.46059 5.17327 4.86128 4.61377 4.86128ZM11.5435 10.8859H9.8616V8.0265C9.8616 7.34146 9.61684 6.87341 9.00832 6.87341C8.54342 6.87341 8.26825 7.18789 8.14587 7.49424C8.10001 7.60431 8.08769 7.75421 8.08769 7.90726V10.8859H6.40576V5.65639H8.08769V6.38414C8.33246 6.03559 8.71481 5.534 9.60452 5.534C10.7086 5.534 11.5438 6.26175 11.5438 7.83073L11.5435 10.8859Z"
        fill="white"
      />
    </svg>
  );
};
