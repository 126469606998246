/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PropertyWrapper = ({
  property1,
  className,
  groupClassName,
  divClassName,
  divClassNameOverride,
  divClassName1,
}) => {
  return (
    <div className={`property-wrapper ${property1} ${className}`}>
      <div className={`group ${groupClassName}`}>
        <div className={`text-wrapper ${divClassName}`}>students</div>
        <div className={`div ${divClassNameOverride}`}>educators</div>
        <div className={`text-wrapper-2 ${divClassName1}`}>people</div>
      </div>
    </div>
  );
};

PropertyWrapper.propTypes = {
  property1: PropTypes.oneOf(["two", "one", "three", "four"]),
};
