/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Property1LogoFacebook1StateActive } from "../../icons/Property1LogoFacebook1StateActive";
import { Property1LogoFacebook1StateDefault } from "../../icons/Property1LogoFacebook1StateDefault";
import { Property1LogoInstagram1StateActive } from "../../icons/Property1LogoInstagram1StateActive";
import { Property1LogoInstagram1StateDefault } from "../../icons/Property1LogoInstagram1StateDefault";
import { Property1LogoLinkedin1StateActive } from "../../icons/Property1LogoLinkedin1StateActive";
import { Property1LogoLinkedin1StateDefault } from "../../icons/Property1LogoLinkedin1StateDefault";
import { Property1LogoTwitter1StateActive } from "../../icons/Property1LogoTwitter1StateActive";
import { Property1LogoTwitter1StateDefault } from "../../icons/Property1LogoTwitter1StateDefault";
import { Property1LogoYoutube1StateActive } from "../../icons/Property1LogoYoutube1StateActive";
import { Property1LogoYoutube1StateDefault } from "../../icons/Property1LogoYoutube1StateDefault";
import "./style.css";

export const Component3 = ({ property1, state }) => {
  return (
    <>
      {state === "default" && property1 === "logo-facebook-1" && (
        <Property1LogoFacebook1StateDefault className="property-logo" />
      )}

      {state === "default" && property1 === "logo-twitter-1" && (
        <Property1LogoTwitter1StateDefault className="property-logo" />
      )}

      {property1 === "logo-linkedin-1" && state === "default" && (
        <Property1LogoLinkedin1StateDefault className="property-logo" />
      )}

      {state === "default" && property1 === "logo-instagram-1" && (
        <Property1LogoInstagram1StateDefault className="property-logo" />
      )}

      {property1 === "logo-youtube-1" && state === "default" && (
        <Property1LogoYoutube1StateDefault className="property-logo" />
      )}

      {state === "active" && property1 === "logo-facebook-1" && (
        <Property1LogoFacebook1StateActive className="property-logo" />
      )}

      {property1 === "logo-twitter-1" && state === "active" && (
        <Property1LogoTwitter1StateActive className="property-logo" />
      )}

      {property1 === "logo-linkedin-1" && state === "active" && (
        <Property1LogoLinkedin1StateActive className="property-logo" />
      )}

      {state === "active" && property1 === "logo-instagram-1" && (
        <Property1LogoInstagram1StateActive className="property-logo" />
      )}

      {property1 === "logo-youtube-1" && state === "active" && (
        <Property1LogoYoutube1StateActive className="property-logo" />
      )}
    </>
  );
};

Component3.propTypes = {
  property1: PropTypes.oneOf([
    "logo-instagram-1",
    "logo-facebook-1",
    "logo-twitter-1",
    "logo-youtube-1",
    "logo-linkedin-1",
  ]),
  state: PropTypes.oneOf(["active", "default"]),
};
