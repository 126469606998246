/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1LogoTwitter1StateActive = ({ className }) => {
  return (
    <svg
      className={`property-1-logo-twitter-1-state-active ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M23.25 5.13281C22.406 5.49954 21.513 5.74115 20.5992 5.85C21.5595 5.28768 22.2817 4.39433 22.6303 3.3375C21.7224 3.8684 20.7307 4.24091 19.6978 4.43906C19.2629 3.98322 18.7397 3.62058 18.1603 3.37319C17.5808 3.12581 16.9571 2.99884 16.327 3C13.7761 3 11.7117 5.03437 11.7117 7.54219C11.7099 7.89101 11.7499 8.2388 11.8308 8.57812C10.0016 8.49237 8.2104 8.02574 6.57187 7.20809C4.93333 6.39043 3.48351 5.23976 2.31516 3.82969C1.90527 4.52068 1.6885 5.30908 1.6875 6.1125C1.6875 7.6875 2.50922 9.07969 3.75 9.89531C3.01487 9.87787 2.29481 9.6833 1.65094 9.32812V9.38438C1.65094 11.5875 3.24469 13.4203 5.35406 13.8375C4.9574 13.9432 4.54864 13.9968 4.13812 13.9969C3.84683 13.9974 3.5562 13.9691 3.27047 13.9125C3.85687 15.7172 5.56359 17.0297 7.58531 17.0672C5.94252 18.3332 3.9256 19.0175 1.85156 19.0125C1.48341 19.012 1.11561 18.99 0.75 18.9469C2.85993 20.2942 5.31255 21.0068 7.81594 21C16.3172 21 20.9616 14.0766 20.9616 8.07188C20.9616 7.875 20.9564 7.67812 20.947 7.48594C21.8485 6.84472 22.6283 6.04786 23.25 5.13281Z"
        fill="url(#paint0_linear_2_4444)"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2_4444"
          x1="32.3595"
          x2="0.749999"
          y1="13.681"
          y2="13.681"
        >
          <stop className="stop" stopColor="#1269B7" />
          <stop className="stop" offset="1" stopColor="#AF62E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
