/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1LogoFacebook1StateDefault = ({ className }) => {
  return (
    <svg
      className={`property-1-logo-facebook-1-state-default ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M22.5 12.0633C22.5 6.26484 17.7984 1.56328 12 1.56328C6.20156 1.56328 1.5 6.26484 1.5 12.0633C1.5 17.3039 5.33906 21.6478 10.3594 22.4362V15.0994H7.69266V12.0633H10.3594V9.75C10.3594 7.1189 11.9273 5.66437 14.3255 5.66437C15.4744 5.66437 16.6763 5.86968 16.6763 5.86968V8.4539H15.3516C14.048 8.4539 13.6402 9.26297 13.6402 10.0945V12.0633H16.552L16.087 15.0994H13.6406V22.4372C18.6609 21.6492 22.5 17.3053 22.5 12.0633Z"
        fill="#3D3D3D"
        fillOpacity="0.6"
        fillRule="evenodd"
      />
    </svg>
  );
};
