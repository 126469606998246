/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1LogoYoutube1StateActive = ({ className }) => {
  return (
    <svg
      className={`property-1-logo-youtube-1-state-active ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2_4451)">
        <path
          className="path"
          d="M23.8425 6.97453C23.8425 4.86516 22.291 3.16828 20.3738 3.16828C17.7769 3.04687 15.1285 3 12.4219 3H11.5782C8.87817 3 6.22504 3.04687 3.62817 3.16875C1.71567 3.16875 0.164103 4.875 0.164103 6.98437C0.0469159 8.65266 -0.00277159 10.3214 4.091e-05 11.9902C-0.00464659 13.6589 0.0484784 15.3292 0.159416 17.0011C0.159416 19.1105 1.71098 20.8214 3.62348 20.8214C6.3516 20.948 9.15004 21.0042 11.9954 20.9995C14.8454 21.0089 17.636 20.9495 20.3672 20.8214C22.2844 20.8214 23.836 19.1105 23.836 17.0011C23.9485 15.3277 24 13.6589 23.9954 11.9855C24.006 10.3167 23.955 8.64641 23.8425 6.97453ZM9.70317 16.5886V7.37766L16.5 11.9808L9.70317 16.5886Z"
          fill="url(#paint0_linear_2_4451)"
        />
      </g>
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2_4451"
          x1="33.7123"
          x2="-0.00024468"
          y1="13.6813"
          y2="13.6813"
        >
          <stop className="stop" stopColor="#1269B7" />
          <stop className="stop" offset="1" stopColor="#AF62E5" />
        </linearGradient>
        <clipPath className="clip-path" id="clip0_2_4451">
          <rect className="rect" fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
