/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Component48 = ({ className }) => {
  return (
    <svg
      className={`component-4-8 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_6_1567)">
        <path
          className="path"
          d="M9.23797 2.0779C9.88612 2.07983 10.5072 2.33817 10.9655 2.79648C11.4238 3.2548 11.6821 3.87585 11.6841 4.52401V9.4157C11.6821 10.0639 11.4238 10.6849 10.9655 11.1432C10.5072 11.6015 9.88612 11.8599 9.23797 11.8618H4.34627C3.69812 11.8599 3.07706 11.6015 2.61875 11.1432C2.16043 10.6849 1.9021 10.0639 1.90016 9.4157V4.52401C1.9021 3.87585 2.16043 3.2548 2.61875 2.79648C3.07706 2.33817 3.69812 2.07983 4.34627 2.0779H9.23797ZM9.23797 1.09961H4.34627C2.46281 1.09961 0.921875 2.64055 0.921875 4.52401V9.4157C0.921875 11.2992 2.46281 12.8401 4.34627 12.8401H9.23797C11.1214 12.8401 12.6624 11.2992 12.6624 9.4157V4.52401C12.6624 2.64055 11.1214 1.09961 9.23797 1.09961Z"
          fill="white"
        />
        <path
          className="path"
          d="M9.97175 4.52396C9.82662 4.52396 9.68475 4.48092 9.56408 4.40029C9.44341 4.31966 9.34936 4.20506 9.29382 4.07098C9.23828 3.9369 9.22375 3.78936 9.25206 3.64702C9.28038 3.50468 9.35026 3.37394 9.45288 3.27132C9.5555 3.1687 9.68625 3.09881 9.82859 3.0705C9.97093 3.04218 10.1185 3.05671 10.2526 3.11225C10.3866 3.16779 10.5012 3.26184 10.5819 3.38251C10.6625 3.50318 10.7055 3.64505 10.7055 3.79018C10.7057 3.8866 10.6869 3.98211 10.6501 4.07123C10.6133 4.16035 10.5592 4.24132 10.4911 4.3095C10.4229 4.37768 10.3419 4.43172 10.2528 4.46852C10.1637 4.50533 10.0682 4.52416 9.97175 4.52396ZM6.79212 5.01297C7.17914 5.01297 7.55748 5.12774 7.87928 5.34276C8.20108 5.55778 8.45189 5.86339 8.6 6.22096C8.74811 6.57852 8.78686 6.97198 8.71135 7.35156C8.63585 7.73115 8.44948 8.07983 8.17581 8.3535C7.90214 8.62716 7.55347 8.81353 7.17388 8.88904C6.79429 8.96454 6.40083 8.92579 6.04327 8.77768C5.6857 8.62958 5.38009 8.37876 5.16507 8.05696C4.95005 7.73516 4.83528 7.35683 4.83528 6.9698C4.83584 6.45099 5.04218 5.95358 5.40904 5.58673C5.7759 5.21987 6.2733 5.01352 6.79212 5.01297ZM6.79212 4.03468C6.21161 4.03468 5.64413 4.20683 5.16145 4.52934C4.67877 4.85186 4.30257 5.31026 4.08042 5.84658C3.85827 6.38291 3.80014 6.97306 3.91339 7.54242C4.02665 8.11178 4.30619 8.63476 4.71667 9.04525C5.12716 9.45573 5.65015 9.73528 6.2195 9.84853C6.78886 9.96178 7.37902 9.90366 7.91534 9.6815C8.45166 9.45935 8.91007 9.08315 9.23258 8.60047C9.5551 8.11779 9.72724 7.55032 9.72724 6.9698C9.72724 6.19136 9.418 5.4448 8.86756 4.89436C8.31712 4.34392 7.57056 4.03468 6.79212 4.03468Z"
          fill="white"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_6_1567">
          <rect
            className="rect"
            fill="white"
            height="13.4177"
            transform="translate(0.083252 0.260986)"
            width="13.4177"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
